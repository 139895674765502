import React from "react";

import EmailIcon from "../assets/email.png";
import InstagramIcon from "../assets/instagram.png";
import FacebookIcon from "../assets/facebook.png";

function Footer() {
  return (
    <div className="footer">
      <a href="mailto:info@mareaa1@live.com">
        <span>
          <img src={EmailIcon} alt="email" className="icon" />
        </span>
      </a>
      <a href="https://www.instagram.com/artbymarea_">
        <span>
          <img src={InstagramIcon} alt="instagram" className="icon" />
        </span>
      </a>
      <a href="https://www.facebook.com/marea.ascic">
        <span>
          <img src={FacebookIcon} alt="facebook" className="icon" />
        </span>
      </a>
      <h3 className="FooterTitle">Copyright © 2022 Art By Marea</h3>
    </div>
  );
}

export default Footer;
