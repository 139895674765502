import React from "react";

import { motion } from "framer-motion";

function Services() {
  return (
    <motion.div
      className="servicesContainer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* <h1 className="sectionTitle">About Me</h1> */}
      <div className="services">
        <p className="servicesIntoText">
          In addition to offering a gallery of existing works to choose from,
          the following services are available:
        </p>
        <h2>Portraits</h2>
        <p>
          Sketches or oil paintings of subject. Can be completed from a
          photograph or during a sitting.
        </p>
        <p>
          Send an email to <a href="mailto:mareaa1@live">mareaa1@live.com</a>{" "}
          with your request - include desired medium and size and whether to be
          completed from a photograph or a sitting (if a sitting, then that will
          be completed at a location nominated by you). I will quote a cost for
          the commission and upon acceptance and payment of a 10% deposit, the
          work can proceed.
        </p>
        <br />
        <h2>Other Commissions</h2>
        <p>
          I can complete sketches and paintings of pets, other types of
          favourite animals, plants, structures, scenery, cars, motorbikes etc.
          You can provide a photograph of the subject or I can provide
          suggestions. Upon confirmation of the subject and the desired medium
          and size. I will quote a cost for the commission and upon acceptance
          and payment of a 10% deposit, the work can proceed.
        </p>
        <p>
          Your request for the work should include delivery details and my quote
          will include an appropriate delivery charge.
        </p>
        <p>
          The turnaround time for my commissioned works will vary depending on
          the medium, size and complexity of the subject, but will usually be in
          the range of 4-7 days (NOTE: this is the completion time, but if the
          work is an oil painting, then an additional time of approximately 14
          days must be allowed for to work to completely dry).
        </p>
      </div>
    </motion.div>
  );
}

export default Services;
