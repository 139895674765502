import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import image5 from "../assets/image5.png";
import image6 from "../assets/image6.png";
import image7 from "../assets/image7.png";
import image8 from "../assets/image8.png";
import image9 from "../assets/image9.png";
import image10 from "../assets/image10.png";
import image11 from "../assets/image11.png";
import image12 from "../assets/image12.png";
import image13 from "../assets/image13.png";
import image14 from "../assets/image14.png";
import image15 from "../assets/image15.png";
import image16 from "../assets/image16.png";
import image17 from "../assets/image17.png";
import image18 from "../assets/image18.png";
import image19 from "../assets/image19.png";
import image20 from "../assets/image20.png";
import image21 from "../assets/image21.png";
import image22 from "../assets/image22.png";
import image23 from "../assets/image23.png";
import image24 from "../assets/image24.png";
import image25 from "../assets/image25.png";
import image26 from "../assets/image26.png";
import image27 from "../assets/image27.png";
import image28 from "../assets/image28.png";
import image29 from "../assets/image29.png";
import image30 from "../assets/image30.png";
import image31 from "../assets/image31.png";
import image32 from "../assets/image32.png";
import image33 from "../assets/image33.png";
import image34 from "../assets/image34.png";
import image35 from "../assets/image35.png";
import image36 from "../assets/image36.png";
import image37 from "../assets/image37.png";
import image38 from "../assets/image38.png";
import image39 from "../assets/image39.png";
import image40 from "../assets/image40.png";
import image41 from "../assets/image41.png";
import image42 from "../assets/image42.png";
import image43 from "../assets/image43.png";
import image44 from "../assets/image44.png";
import image45 from "../assets/image45.png";
import image46 from "../assets/image46.png";
import image47 from "../assets/image47.png";
import image48 from "../assets/image48.png";
import image49 from "../assets/image49.png";
import image50 from "../assets/image50.png";
import image51 from "../assets/image51.png";
import image52 from "../assets/image52.png";
import image53 from "../assets/image53.png";
import image54 from "../assets/image54.png";
import image55 from "../assets/image55.png";
import image56 from "../assets/image56.png";
import image57 from "../assets/image57.png";
import image58 from "../assets/image58.png";
import image59 from "../assets/image59.png";
import image60 from "../assets/image60.png";
import image61 from "../assets/image61.png";
import image62 from "../assets/image62.png";
import image63 from "../assets/image63.png";
import image64 from "../assets/image64.png";
import image65 from "../assets/image65.png";
import image66 from "../assets/image66.png";
import image67 from "../assets/image67.png";
import image68 from "../assets/image68.png";
import image69 from "../assets/image69.png";
import image70 from "../assets/image70.png";
import image71 from "../assets/image71.png";
import image72 from "../assets/image72.png";
import image73 from "../assets/image73.png";
import image74 from "../assets/image74.png";
import image75 from "../assets/image75.png";
import image76 from "../assets/image76.png";
import image77 from "../assets/image77.png";
import image78 from "../assets/image78.png";
import image79 from "../assets/image79.png";
import image80 from "../assets/image80.png";
import image81 from "../assets/image81.png";
import image82 from "../assets/image82.png";
import image83 from "../assets/image83.png";
import image84 from "../assets/image84.png";
import image85 from "../assets/image85.png";
import image86 from "../assets/image86.png";
import image87 from "../assets/image87.png";
import image88 from "../assets/image88.png";
import image89 from "../assets/image89.png";
import image90 from "../assets/image90.png";
import image91 from "../assets/image91.png";
import image92 from "../assets/image92.png";
import image93 from "../assets/image93.png";
import image94 from "../assets/image94.png";
import image95 from "../assets/image95.png";
import image96 from "../assets/image96.png";
import image97 from "../assets/image97.png";
import image98 from "../assets/image98.png";
import image99 from "../assets/image99.png";
import image100 from "../assets/image100.png";
import image101 from "../assets/image101.png";
import image102 from "../assets/image102.png";
import image103 from "../assets/image103.png";
import image104 from "../assets/image104.png";
import image105 from "../assets/image105.png";
import image106 from "../assets/image106.png";
import image107 from "../assets/image107.png";
import image108 from "../assets/image108.png";
import image109 from "../assets/image109.png";
import image110 from "../assets/image110.png";
import image111 from "../assets/image111.png";
import image112 from "../assets/image112.png";
import image113 from "../assets/image113.png";
import image114 from "../assets/image114.png";
import image115 from "../assets/image115.png";
import image116 from "../assets/image116.png";
import image117 from "../assets/image117.png";
import image118 from "../assets/image118.png";
import image119 from "../assets/image119.png";
import image120 from "../assets/image120.png";
import image121 from "../assets/image121.png";
import image122 from "../assets/image122.png";
import image123 from "../assets/image123.png";
import image124 from "../assets/image124.png";

const paintings = [
  {
    id: "1",
    title: "Picking at the Shore",
    dimensions: "60 x 75 cm",
    date: "3rd July 2022",
    collection: "Seascape",
    price: 800,
    image: image1,
  },
  {
    id: "2",
    title: "Midday Perch",
    date: "29th June 2022",
    dimensions: "24 x 32 inches",
    collection: "Seascape",
    price: 800,
    image: image2,
  },
  {
    id: "3",
    title: "Remnant",
    date: "2nd July 2022",
    dimensions: "60 x 75 cm",
    collection: "Seascape",
    price: 1000,
    image: image3,
  },
  {
    id: "4",
    title: "Storm Breaker",
    date: "5th July 2022",
    dimensions: "70x 100 cm",
    collection: "Seascape",
    price: 1000,
    image: image4,
  },
  {
    id: "5",
    title: "Jade Waves",
    date: "26th June 2022",
    dimensions: "Approx. 75 inches",
    collection: "Seascape",
    price: 5000,
    image: image5,
  },
  {
    id: "6",
    title: "Happy Family",
    date: "21st May 2022",
    dimensions: "16 x 20 inches",
    collection: "Fauna",
    price: 600,
    image: image6,
  },
  {
    id: "7",
    title: "Midnight Swell",
    date: "8th June 2022",
    dimensions: "70 x 100 cm",
    collection: "Seascape",
    price: 1000,
    image: image7,
  },
  {
    id: "8",
    title: "Dusky Waves",
    date: "8th June 2022",
    dimensions: "16 x 20 inches",
    collection: "Seascape",
    price: 800,
    image: image8,
  },
  {
    id: "9",
    title: "Mountain Lake",
    date: "11th June 2022",
    dimensions: "30 x 30 inches",
    collection: "Landscape",
    price: 1500,
    image: image9,
  },
  {
    id: "10",
    title: "Crystal Break",
    date: "21st November 2021",
    dimensions: "60 x 75 cm",
    collection: "Surrealism",
    price: 1200,
    image: image10,
  },
  {
    id: "11",
    title: "Autumn Stroll",
    date: "20th May 2021",
    dimensions: "70 x 35 cm",
    collection: "Landscape",
    price: 1500,
    image: image11,
  },
  {
    id: "12",
    title: "Glory Shore",
    date: "17th May 2022",
    dimensions: "19 x 16 cm",
    collection: "Seascape",
    price: 800,
    image: image12,
  },
  {
    id: "13",
    title: "The King",
    date: "23rd April 2022",
    dimensions: "16 x 20 inches",
    collection: "Fauna",
    price: 800,
    image: image13,
  },
  {
    id: "14",
    title: "Orchids",
    date: "20th October 2021",
    dimensions: "60 x 75 cm",
    collection: "Flora",
    price: 600,
    image: image14,
  },
  {
    id: "15",
    title: "Almost Home",
    date: "14 January 2022",
    dimensions: "60 x 75 cm",
    collection: "Landscape",
    price: 2000,
    image: image15,
  },
  {
    id: "16",
    title: "King at Rest",
    date: "3rd December 2021",
    dimensions: "60 x 75 cm",
    collection: "Fauna",
    price: 600,
    image: image16,
  },
  {
    id: "17",
    title: "Forest Rays",
    date: "14th April 2022",
    dimensions: "60 x 75 cm",
    collection: "Landscape",
    price: 1200,
    image: image17,
  },
  {
    id: "18",
    title: "Crimson Sky",
    date: "24th April 2022",
    dimensions: "30 x 30 inches",
    collection: "Landscape",
    price: 1200,
    image: image18,
  },
  {
    id: "19",
    title: "Purple Perch",
    date: "18th March 2022",
    dimensions: "60 x 75 cm",
    collection: "Flora",
    price: 1000,
    image: image19,
  },
  {
    id: "20",
    title: "Reaching Out",
    date: "July 1987",
    dimensions: "Approx. 60 x 75 cm",
    collection: "Sketches",
    price: 400,
    image: image20,
  },
  {
    id: "21",
    title: "The Underminer",
    date: "July 1987",
    dimensions: "Approx. 60 x 75 cm",
    collection: "Sketches",
    price: 400,
    image: image21,
  },
  {
    id: "22",
    title: "Destitute",
    date: "July 1987",
    dimensions: "Approx. 60 x 75 cm",
    collection: "Sketches",
    price: 400,
    image: image22,
  },
  {
    id: "23",
    title: "Eye of the Beholder",
    date: "July 1987",
    dimensions: "Approx. 60 x 75 cm",
    collection: "Sketches",
    price: 400,
    image: image23,
  },
  {
    id: "24",
    title: "Corruption",
    date: "July 1987",
    dimensions: "60 x 75 cm",
    collection: "Sketches",
    price: 400,
    image: image24,
  },
  {
    id: "25",
    title: "Calamity",
    date: "July 1987",
    dimensions: "Approx. 60 x 75 cm",
    collection: "Sketches",
    price: 400,
    image: image25,
  },
  {
    id: "26",
    title: "Forest Path",
    date: "10th April 2022",
    dimensions: "16 x 20 inches",
    collection: "Landscape",
    price: 1200,
    image: image26,
  },
  {
    id: "27",
    title: "Panther",
    date: "28th August 2021",
    dimensions: "16 x 20 inches",
    collection: "Fauna",
    price: 1000,
    image: image27,
  },
  {
    id: "28",
    title: "Couple's Sanctuary",
    date: "15th May 2022",
    dimensions: "10 x 20 inches",
    collection: "Seascape",
    price: 1000,
    image: image28,
  },
  {
    id: "29",
    title: "Sitting Room Window",
    date: "9th May 2022",
    dimensions: "16 x 20 inches",
    collection: "Structures",
    price: 1000,
    image: image29,
  },
  {
    id: "30",
    title: "The Saviour",
    date: "22nd January 2022",
    dimensions: "16 x 20 inches",
    collection: "Surrealism",
    price: 800,
    image: image30,
  },
  {
    id: "31",
    title: "Windswept",
    date: "21st January 2022",
    dimensions: "40 x 40 cm",
    collection: "Flora",
    price: 800,
    image: image31,
  },
  {
    id: "32",
    title: "Eagle",
    date: "16th May 2022",
    dimensions: "9 x 12 inches",
    collection: "Fauna",
    price: 400,
    image: image32,
  },
  {
    id: "33",
    title: "Purple Haze",
    date: "25th May 2022",
    dimensions: "40 x 30 cm",
    collection: "Flora",
    price: 600,
    image: image33,
  },
  {
    id: "34",
    title: "The Scenic Route",
    date: "5th June 2022",
    dimensions: "14 x 18 inches",
    collection: "Landscape",
    price: 800,
    image: image34,
  },
  {
    id: "35",
    title: "Ruffled",
    date: "5th May 2022",
    dimensions: "9 x 12 inches",
    collection: "Fauna",
    price: 400,
    image: image35,
  },
  {
    id: "36",
    title: "American Eagle",
    date: "10th May 2022",
    dimensions: "8 x 10 inches",
    collection: "Fauna",
    price: 400,
    image: image36,
  },
  {
    id: "37",
    title: "Golden Meadow",
    date: "3rd April 2022",
    dimensions: "16 x 20 inches",
    collection: "Landscape",
    price: 1200,
    image: image37,
  },
  {
    id: "38",
    title: "Forest Lodge",
    date: "22nd May 2022",
    dimensions: "16 x 20 inches",
    collection: "Landscape",
    price: 1200,
    image: image38,
  },
  {
    id: "39",
    title: "Old Chapel Door",
    date: "7th June 2022",
    dimensions: "14 x 18 inches",
    collection: "Structures",
    price: 800,
    image: image39,
  },
  {
    id: "40",
    title: "Old Homestead",
    date: "December 1987",
    dimensions: "14 x 18 inches",
    collection: "Landscape",
    price: 300,
    image: image40,
  },
  {
    id: "41",
    title: "Rose Bloom",
    date: "21st May 2021",
    dimensions: "45.7 x 45.7 cm",
    collection: "Flora",
    price: 400,
    image: image41,
  },
  {
    id: "42",
    title: "The Calm",
    date: "29th May 2022",
    dimensions: "10 x 12 inches",
    collection: "Landscape",
    price: 400,
    image: image42,
  },
  {
    id: "43",
    title: "Sunset Reflection",
    date: "29th of May 2022",
    dimensions: "11 x 14 inches",
    collection: "Seascape",
    price: 1000,
    image: image43,
  },
  {
    id: "44",
    title: "Country Playground",
    date: "27th June 2022",
    dimensions: "10 x 14 inches",
    collection: "Landscape",
    price: 600,
    image: image44,
  },
  {
    id: "45",
    title: "Dark Stead",
    date: "23rd April 2022",
    dimensions: "10 x 14 inches",
    collection: "Fauna",
    price: 400,
    image: image45,
  },
  {
    id: "46",
    title: "Purple Rows",
    date: "30th May 2022",
    dimensions: "40 x 30 cm",
    collection: "Landscape",
    price: 800,
    image: image46,
  },
  {
    id: "47",
    title: "The Dove",
    date: "11th March 2022",
    dimensions: "11 x 14 inches",
    collection: "Fauna",
    price: 600,
    image: image47,
  },
  {
    id: "48",
    title: "The Swoop",
    date: "23rd December 2021",
    dimensions: "16 x 20 inches",
    collection: "Fauna",
    price: 1000,
    image: image48,
  },
  {
    id: "49",
    title: "Kindling",
    date: "17th January 2022",
    dimensions: "24 x 18 inches",
    collection: "Landscape",
    price: 1800,
    image: image49,
  },
  {
    id: "50",
    title: "Blue Surge",
    date: "20th January 2022",
    dimensions: "24 x 18 inches",
    collection: "Surrealism",
    price: 800,
    image: image50,
  },
  {
    id: "51",
    title: "Red Bloom",
    date: "July 2021",
    dimensions: "24 x 18 inches",
    collection: "Landscape",
    price: 1000,
    image: image51,
  },
  {
    id: "52",
    title: "Morning Glow",
    date: "June 2021",
    dimensions: "24 x 18 inches",
    collection: "Seascape",
    price: 800,
    image: image52,
  },
  {
    id: "53",
    title: "Lake Cabin",
    date: "3rd April 2022",
    dimensions: "24 x 18 inches",
    collection: "Landscape",
    price: 1000,
    image: image53,
  },
  {
    id: "54",
    title: "Mountain Sunset",
    date: "June 2021",
    dimensions: "24 x 18 inches",
    collection: "Landscape",
    price: 1200,
    image: image54,
  },
  {
    id: "55",
    title: "Fire & Water",
    date: "May 2021",
    dimensions: "24 x 18 inches",
    collection: "Surrealism",
    price: 1000,
    image: image55,
  },
  {
    id: "56",
    title: "Sunset on The Lake",
    date: "August 2021",
    dimensions: "20 x 24 inches",
    collection: "Landscape",
    price: 1200,
    image: image56,
  },
  {
    id: "57",
    title: "Mates",
    date: "August 2021",
    dimensions: "20 x 24 inches",
    collection: "Fauna",
    price: 800,
    image: image57,
  },
  {
    id: "58",
    title: "Through the Canopy",
    date: "18th December 2021",
    dimensions: "16 x 20 inches",
    collection: "Surrealism",
    price: 800,
    image: image58,
  },
  {
    id: "59",
    title: "Purple Moonlight",
    date: "16th December 2022",
    dimensions: "16 x 20 inches",
    collection: "Landscape",
    price: 1200,
    image: image59,
  },
  {
    id: "60",
    title: "Red Riding Hood",
    date: "27th December 2021",
    dimensions: "16 x 20 inches",
    collection: "Surrealism",
    price: 800,
    image: image60,
  },
  {
    id: "61",
    title: "Light Waterfall",
    date: "August 2021",
    dimensions: "16 x 20 inches",
    collection: "Landscape",
    price: 800,
    image: image61,
  },
  {
    id: "62",
    title: "Power & Wisdom",
    date: "8th December 2021",
    dimensions: "40 x 50 cm",
    collection: "Surrealism",
    price: 1000,
    image: image62,
  },
  {
    id: "63",
    title: "Robins",
    date: "10th December 2021",
    dimensions: "40 x 50 cm",
    collection: "Fauna",
    price: 1400,
    image: image63,
  },
  {
    id: "64",
    title: "Canyon Stream",
    date: "May 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1200,
    image: image64,
  },
  {
    id: "65",
    title: "Reflection",
    date: "22nd January 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1800,
    image: image65,
  },
  {
    id: "66",
    title: "Meadow Stroll",
    date: "12th February 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1000,
    image: image66,
  },
  {
    id: "67",
    title: "Old Farm Track",
    date: "March 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1200,
    image: image67,
  },
  {
    id: "68",
    title: "Fresh Morning",
    date: "23rd December 2021",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1200,
    image: image68,
  },
  {
    id: "69",
    title: "The Lone Fisherman",
    date: "December 2021",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 800,
    image: image69,
  },
  {
    id: "70",
    title: "Flowers at Dusk",
    date: "February 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1000,
    image: image70,
  },
  {
    id: "71",
    title: "Watergrass",
    date: "March 2022",
    dimensions: "40 x 50 cm",
    collection: "Seascape",
    price: 1000,
    image: image71,
  },
  {
    id: "72",
    title: "Pelicans",
    date: "March 2022",
    dimensions: "40 x 50 cm",
    collection: "Seascape",
    price: 800,
    image: image72,
  },
  {
    id: "73",
    title: "Spring Flow",
    date: "March 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1500,
    image: image73,
  },
  {
    id: "74",
    title: "Forest Ripples",
    date: "January 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1600,
    image: image74,
  },
  {
    id: "75",
    title: "Canyon Flow",
    date: "August 2021",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1200,
    image: image75,
  },
  {
    id: "76",
    title: "Wise Friend",
    date: "March 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1500,
    image: image76,
  },
  {
    id: "77",
    title: "Perched",
    date: "16th February 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1200,
    image: image77,
  },
  {
    id: "78",
    title: "Glorious Dawn",
    date: "7th January 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 1200,
    image: image78,
  },
  {
    id: "79",
    title: "Morning Graze",
    date: "March 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 800,
    image: image79,
  },
  {
    id: "80",
    title: "Spirited",
    date: "January 2022",
    dimensions: "40 x 50 cm",
    collection: "Fauna",
    price: 1000,
    image: image80,
  },
  {
    id: "81",
    title: "Hillside Cottage",
    date: "May 2022",
    dimensions: "40 x 50 cm",
    collection: "Landscape",
    price: 800,
    image: image81,
  },
  {
    id: "82",
    title: "Field Wren",
    date: "November 2021",
    dimensions: "40 x 50 cm",
    collection: "Fauna",
    price: 1200,
    image: image82,
  },
  {
    id: "83",
    title: "Wagtail",
    date: "November 2021",
    dimensions: "40 x 40 cm",
    collection: "Fauna",
    price: 1200,
    image: image83,
  },
  {
    id: "84",
    title: "Carriage Wheel",
    date: "November 2021",
    dimensions: "40 x 40 cm",
    collection: "Landscape",
    price: 800,
    image: image84,
  },
  {
    id: "85",
    title: "Birds at the Bucket",
    date: "9th December 2021",
    dimensions: "40 x 40 cm",
    collection: "Fauna",
    price: 1200,
    image: image85,
  },
  {
    id: "86",
    title: "Vukovar",
    date: "4th April 2024",
    dimensions: "20 x 24 inches",
    collection: "Croatia",
    price: 1600,
    image: image86,
  },
  {
    id: "87",
    title: "Angel's Wish",
    date: "6th March 2024",
    dimensions: "30 x 40 inches",
    collection: "Surrealism",
    price: 2400,
    image: image87,
  },
  {
    id: "88",
    title: "Modric",
    date: "24th February 2024",
    dimensions: "12 x 16 inches",
    collection: "Croatia",
    price: 800,
    image: image88,
  },
  {
    id: "89",
    title: "The Summit",
    date: "18th March 2024",
    dimensions: "16 x 20 inches",
    collection: "Croatia",
    price: 1200,
    image: image89,
  },
  {
    id: "90",
    title: "Zastava i Krunica",
    date: "12th March 2024",
    dimensions: "20 x 20 inches",
    collection: "Croatia",
    price: 1600,
    image: image90,
  },
  {
    id: "91",
    title: "Dom",
    date: "1st April 2024",
    dimensions: "14 x 18 inches",
    collection: "Croatia",
    price: 1200,
    image: image91,
  },
  {
    id: "92",
    title: "Za Dom Spremni",
    date: "28th March 2024",
    dimensions: "24 x 36 inches",
    collection: "Croatia",
    price: 2400,
    image: image92,
  },
  {
    id: "93",
    title: "Nova Zastava",
    date: "4th April 2024",
    dimensions: "20 x 24 inches",
    collection: "Croatia",
    price: 2400,
    image: image93,
  },
  {
    id: "94",
    title: "Lijepotica",
    date: "15th March 2024",
    dimensions: "12 x 16 inches",
    collection: "Croatia",
    price: 600,
    image: image94,
  },
  {
    id: "95",
    title: "The Royal National Park",
    date: "6th April 2024",
    dimensions: "20 x 24 inches",
    collection: "Landscape",
    price: 800,
    image: image95,
  },
  {
    id: "96",
    title: "Sky Burst",
    date: "6th December 2023",
    dimensions: "20 x 30 inches",
    collection: "Seascape",
    price: 2000,
    image: image96,
  },
  {
    id: "97",
    title: "Candy Sky",
    date: "8th December 2023",
    dimensions: "16 x 20 inches",
    collection: "Seascape",
    price: 600,
    image: image97,
  },
  {
    id: "98",
    title: "Bliss",
    date: "15th February 2024",
    dimensions: "12 x 16 inches",
    collection: "Surrealism",
    price: 800,
    image: image98,
  },
  {
    id: "99",
    title: "Swirl",
    date: "2nd February 2024",
    dimensions: "14 x 18 inches",
    collection: "Surrealism",
    price: 600,
    image: image99,
  },
  {
    id: "100",
    title: "Blue Moon",
    date: "5th February 2024",
    dimensions: "14 x 18 inches",
    collection: "Fauna",
    price: 600,
    image: image100,
  },
  {
    id: "101",
    title: "Sunday Arvo",
    date: "30th January 2024",
    dimensions: "14 x 18 inches",
    collection: "Landscape",
    price: 1000,
    image: image101,
  },
  {
    id: "102",
    title: "Peaks",
    date: "20th January 2024",
    dimensions: "14 x 18 inches",
    collection: "Seascape",
    price: 800,
    image: image102,
  },
  {
    id: "103",
    title: "Hidden Gem",
    date: "21th February 2024",
    dimensions: "16 x 20 inches",
    collection: "Landscape",
    price: 1200,
    image: image103,
  },
  {
    id: "104",
    title: "Mostar",
    date: "15th February 2024",
    dimensions: "12 x 16 inches",
    collection: "Landscape",
    price: 800,
    image: image104,
  },
  {
    id: "105",
    title: "Dragon Eye",
    date: "14th February 2024",
    dimensions: "40 x 30 cm",
    collection: "Surrealism",
    price: 600,
    image: image105,
  },
  {
    id: "106",
    title: "Thoughts",
    date: "2nd February 2024",
    dimensions: "14 x 18 inches",
    collection: "Surrealism",
    price: 800,
    image: image106,
  },
  {
    id: "107",
    title: "Mind",
    date: "2nd February 2024",
    dimensions: "14 x 18 inches",
    collection: "Surrealism",
    price: 800,
    image: image107,
  },
  {
    id: "108",
    title: "Topaz Waters",
    date: "17th December 2023",
    dimensions: "14 x 18 inches",
    collection: "Landscape",
    price: 800,
    image: image108,
  },
  {
    id: "109",
    title: "Green Nature",
    date: "30th December 2023",
    dimensions: "60 x 90 cm",
    collection: "Flora",
    price: 2000,
    image: image109,
  },
  {
    id: "110",
    title: "Blessed Mary",
    date: "30th August 2022",
    dimensions: "16 x 20 inches",
    collection: "Surrealism",
    price: 1200,
    image: image110,
  },
  {
    id: "111",
    title: "Prayer",
    date: "27th August 2022",
    dimensions: "20 x 24 inches",
    collection: "Surrealism",
    price: 1200,
    image: image111,
  },
  {
    id: "112",
    title: "Palm Beach",
    date: "24th October 2023",
    dimensions: "20 x 30 inches",
    collection: "Seascape",
    price: 1600,
    image: image112,
  },
  {
    id: "113",
    title: "Jungle Walk",
    date: "13th October 2023",
    dimensions: "20 x 30 inches",
    collection: "Landscape",
    price: 2000,
    image: image113,
  },
  {
    id: "114",
    title: "Angel Knight",
    date: "13th January 2024",
    dimensions: "16 x 20 inches",
    collection: "Croatia",
    price: 1200,
    image: image114,
  },
  {
    id: "115",
    title: "Za Dom",
    date: "12th July 2023",
    dimensions: "20 x 25 inches",
    collection: "Croatia",
    price: 600,
    image: image115,
  },
  {
    id: "116",
    title: "Australian Forest",
    date: "18th May 2023",
    dimensions: "20 x 30 inches",
    collection: "Flora",
    price: 2400,
    image: image116,
  },
  {
    id: "117",
    title: "Bog i Hrvati",
    date: "12th April 2024",
    dimensions: "16 x 20 inches",
    collection: "Croatia",
    price: 1200,
    image: image117,
  },
  {
    id: "118",
    title: "Autum Leaves",
    date: "23rd July 2023",
    dimensions: "20 x 16 inches",
    collection: "Flora",
    price: 1200,
    image: image118,
  },
  {
    id: "119",
    title: "Croatian Seas",
    date: "2nd July 2023",
    dimensions: "10 x 12 inches",
    collection: "Croatia",
    price: 800,
    image: image119,
  },
  {
    id: "120",
    title: "Bosnia",
    date: "1st October 2023",
    dimensions: "20 x 25 inches",
    collection: "Structures",
    price: 1200,
    image: image120,
  },
  {
    id: "121",
    title: "Sun Angel",
    date: "7th October 2023",
    dimensions: "40 x 40 inches",
    collection: "Surrealism",
    price: 5000,
    image: image121,
  },
  {
    id: "122",
    title: "Soul Mates",
    date: "13th June 2023",
    dimensions: "20 x 30 inches",
    collection: "Fauna",
    price: 2400,
    image: image122,
  },
  {
    id: "123",
    title: "Dream Catcher",
    date: "10th October 2023",
    dimensions: "40 x 40 inches",
    collection: "Surrealism",
    price: 5000,
    image: image123,
  },
  {
    id: "124",
    title: "Fern Path",
    date: "14th April 2024",
    dimensions: "16 x 20 inches",
    collection: "Landscape",
    price: 800,
    image: image124,
  },
];

export { paintings };
