import React from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from "../pages/Home";
import Services from "../pages/Services";
import Contact from "../pages/Contact";
import IndividualArtwork from "../pages/IndividualArtwork";
import Collections from "../pages/Collections";
import AboutMe from "../pages/AboutMe";
import Paintings from "../pages/Paintings";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/artwork" element={<IndividualArtwork />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/aboutme" element={<AboutMe />} />
        <Route path="/paintings" element={<Paintings />} />
        <Route path="/paintings/moreinfo" element={<IndividualArtwork />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
