import React from "react";

import { motion } from "framer-motion";

import EmailIcon from "../assets/email.png";
import InstagramIcon from "../assets/instagram.png";
import FacebookIcon from "../assets/facebook.png";

function Contact() {
  return (
    <motion.div
      className="contactContainer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="contact">
        <h1 className="contactTitle">Marea Ascic</h1>
        <p className="contactText">
          <span className="contactSpan">M:</span> 0433010484
          <br />
          <br />
          <span className="contactSpan">E:</span>{" "}
          <a href="mailto:info@mareaa1@live.com">mareaa1@live.com</a>
        </p>

        <div className="contactIconContainer">
          <a href="https://www.instagram.com/artbymarea_">
            <span>
              <img src={InstagramIcon} alt="instagram" className="icon" />
            </span>
          </a>
          <a href="https://www.facebook.com/marea.ascic">
            <span>
              <img src={FacebookIcon} alt="facebook" className="icon" />
            </span>
          </a>
        </div>
      </div>
    </motion.div>
  );
}

export default Contact;
