import React from "react";

import { motion } from "framer-motion";
import CollectionItem from "../components/CollectionItem";
import surreal from "../assets/image62.png";
// import portrait from "../assets/portrait.png";
import landscape from "../assets/landscape.png";
import fauna from "../assets/fauna.png";
import flora from "../assets/image19.png";
import sketches from "../assets/image23.png";
import seascape from "../assets/image5.png";
import structure from "../assets/image29.png";
import croatia from "../assets/croatia.png";
import { Link } from "react-router-dom";

function Collections() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        display: "inline-flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div className="collectionContainer">
        <div className="collectionItem">
          <Link
            to="/paintings"
            state={{ collection: "Landscape" }}
            style={{ textDecoration: "none" }}
          >
            <CollectionItem title="Landscapes" img={landscape} />
          </Link>
        </div>

        {/* <Link to="/paintings" state={{ collection: "Portraits" }}>
          <CollectionItem title="Portraits" img={portrait} />
        </Link> */}
        <div className="collectionItem">
          <Link
            to="/paintings"
            state={{ collection: "Fauna" }}
            style={{ textDecoration: "none" }}
          >
            <CollectionItem title="Fauna" img={fauna} />
          </Link>
        </div>
        <div className="collectionItem">
          <Link
            to="/paintings"
            state={{ collection: "Flora" }}
            style={{ textDecoration: "none" }}
          >
            <CollectionItem title="Flora" img={flora} />
          </Link>
        </div>
        <div className="collectionItem">
          <Link
            to="/paintings"
            state={{ collection: "Surrealism" }}
            style={{ textDecoration: "none" }}
          >
            <CollectionItem title="Surrealism" img={surreal} />
          </Link>
        </div>
        <div className="collectionItem">
          <Link
            to="/paintings"
            state={{ collection: "Croatia" }}
            style={{ textDecoration: "none" }}
          >
            <CollectionItem title="Croatia" img={croatia} />
          </Link>
        </div>
        <div className="collectionItem">
          <Link
            to="/paintings"
            state={{ collection: "Seascape" }}
            style={{ textDecoration: "none" }}
          >
            <CollectionItem title="Seascapes" img={seascape} />
          </Link>
        </div>
        <div className="collectionItem">
          <Link
            to="/paintings"
            state={{ collection: "Structures" }}
            style={{ textDecoration: "none" }}
          >
            <CollectionItem title="Structures" img={structure} />
          </Link>
        </div>

        <div className="collectionItem">
          <Link
            to="/paintings"
            state={{ collection: "Sketches" }}
            style={{ textDecoration: "none" }}
          >
            <CollectionItem title="Sketches" img={sketches} />
          </Link>
        </div>
      </div>
    </motion.div>
  );
}

export default Collections;
