import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Masonry from "react-masonry-css";
import { paintings } from "../components/ImageImports";
import { useLocation, Link } from "react-router-dom";

const breakpointColumnsObj = {
  default: 3,
  1500: 3,
  1100: 2,
  700: 1,
};

function Paintings() {
  const location = useLocation();

  let collection;

  if (location.state !== null) {
    collection = location.state.collection;
  }

  function getCollectionPaintings() {
    if (collection !== null) {
      let result = paintings.filter(
        (painting) => painting.collection === collection
      );
      return result;
    }
  }

  let collectionPaintings = getCollectionPaintings();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <motion.div
        className="paintingsOuterContainer"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="paintingsInnerContainer">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {collectionPaintings.map((painting, key) => {
              return (
                <div className="paintingItemContainer" key={key}>
                  <Link to="/paintings/moreinfo" state={{ painting: painting }}>
                    <img
                      src={painting.image}
                      alt={painting.title}
                      className="painting"
                    />
                  </Link>
                </div>
              );
            })}
          </Masonry>
        </div>
      </motion.div>
    </>
  );
}

export default Paintings;
