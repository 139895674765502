import React from "react";

function CollectionItem({ title, img, onClick }) {
  return (
    <div className="innerCollectionItem">
      <img src={img} alt={title} className="collectionImage" />

      <div className="collectionItemTextContainer">
        <p className="collectionItemTitle">{title}</p>
      </div>
    </div>
  );
}

export default CollectionItem;
