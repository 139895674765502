import React from "react";
import bannerImage from "../assets/banner3.JPG";

function Banner() {
  return (
    <div className="banner">
      {/* <h1 className="bannerTitle">Art By Marea</h1> */}
      <a href="/collections" className="bannerButton">
        Collections
      </a>
      <img src={bannerImage} alt="banner" />
    </div>
  );
}

export default Banner;
