import React from "react";

import { motion } from "framer-motion";

import { useLocation } from "react-router-dom";

function IndividualArtwork() {
  const location = useLocation();

  let paintingData;

  if (location.state !== null) {
    paintingData = location.state.painting;
  }

  return (
    <motion.div
      className="artwork"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {paintingData && (
        <div className="artworkContentContainer">
          <div className="artworkImageContainer">
            <img src={paintingData.image} alt={paintingData.title} />
          </div>
          <div className="paintingDataContainer">
            <h2>{paintingData.title}</h2>
            <p>{paintingData.date}</p>
            <p>{paintingData.dimensions}</p>
            <p>${Math.round(paintingData.price / 4)}</p>
            {/* <a href="mailto:mareaa1@live"></a> */}
          </div>
        </div>
      )}
    </motion.div>
  );
}

export default IndividualArtwork;
