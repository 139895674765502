import React from "react";

import { motion } from "framer-motion";

function AboutMe() {
  return (
    <motion.div
      className="visionContainer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* <h1 className="sectionTitle">About Me</h1> */}
      <div className="vision">
        <h2>My Vision</h2>
        <p>
          I am not bound by style, form or subject. My goal is to bring what I
          see and imagine to the canvas. To make something real, vivid and
          inspiring. I have created a broad gallery, so that whoever scrolls
          through my work will find something that moves them and the goal for
          my commissions is to be true to the vision of the client.
        </p>
        <p>
          I can produce works in a variety of mediums and in any range of format
          and size (from small sketch to mural) to fit a particular taste, site
          and budget. My passion for my art drives me and I seek to communicate
          this passion through every one of my works.
        </p>
        <br />
        <h2>My Story</h2>
        <p>
          Since I was 8 years old I remember loving to draw and copy anything I
          could. I spent hours in my room sketching and living in my head. My
          parents and siblings always encouraged this. My mum asked me in my
          teens whether I would be interested in learning how to paint with
          oils. I loved the idea. Every weekend my mum would have a painting
          from classes with the clever and brilliant artist John Bradley.
        </p>
        <p>
          After going for a short time, John told me I had talent and that I
          could do this on my own as I showed true talent. I was surprised but
          soon chose my own subjects to paint. I loved landscapes and birds. My
          parents house was soon filled with my paintings and when people
          visited my parents would proudly say our daughter did that.
        </p>
        <p>
          At school everyone knew me for my art works. For my majors I did
          massive drawings and did well at school. At university I majored in
          Art Fibre and did drawings and massive paintings with the subjects
          being a surreal style from the biblical times. After schooling I tried
          graphic art with Chas and Clarkson but found I didn't like the
          repetitiveness of the jobs and I felt my designs were over used and
          that it wasn't for me.
        </p>
        <p>
          I married and had children and found I didn't have much time for art
          but when I finally did find time it was always there waiting for me.
          After a 17 year marriage and some struggles with being a single mum
          the dam broke and the want to paint and draw started to come back in a
          big way. These days even though I do full time work I put aside a day
          or 2 for painting. Now I am in the mode of wishing I could just paint
          and draw every day but I adore my job also so have worked it out and
          am as happy as anyone can be. I have a wonderful partner and children
          who encourage and support my vision and passion.
        </p>
      </div>
    </motion.div>
  );
}

export default AboutMe;
